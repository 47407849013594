@-webkit-keyframes kf_el_Lwq4XIM_hf_an_gP4t4M2PI {
  0% {
    opacity: 0;
  }
  75% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}
@keyframes kf_el_Lwq4XIM_hf_an_gP4t4M2PI {
  0% {
    opacity: 0;
  }
  75% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes kf_el_IoAzBrlRaH_an_Bq0fVmd7S {
  0% {
    opacity: 0;
  }
  75% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}
@keyframes kf_el_IoAzBrlRaH_an_Bq0fVmd7S {
  0% {
    opacity: 0;
  }
  75% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes kf_el_kEw-LN6Hmx_an_XnGPGDHUC {
  0% {
    opacity: 0;
  }
  75% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}
@keyframes kf_el_kEw-LN6Hmx_an_XnGPGDHUC {
  0% {
    opacity: 0;
  }
  75% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes kf_el_nx_DAFjNLl_an_mIyMkvVwu {
  0% {
    opacity: 0;
  }
  75% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}
@keyframes kf_el_nx_DAFjNLl_an_mIyMkvVwu {
  0% {
    opacity: 0;
  }
  75% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes kf_el_YJBkAijzRs_an_D4z-ww0hq {
  0% {
    opacity: 0;
  }
  75% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}
@keyframes kf_el_YJBkAijzRs_an_D4z-ww0hq {
  0% {
    opacity: 0;
  }
  75% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes kf_el_ipQHtzRGwH_an_FBmFsu36z {
  0% {
    opacity: 0;
  }
  75% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}
@keyframes kf_el_ipQHtzRGwH_an_FBmFsu36z {
  0% {
    opacity: 0;
  }
  75% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes kf_el_YJBkAijzRs_an_55W5iDRlP {
  0% {
    -webkit-transform: translate(0.5000076293945312px, 127px)
      translate(-0.5000076293945312px, -127px) translate(0px, 0px);
    transform: translate(0.5000076293945312px, 127px)
      translate(-0.5000076293945312px, -127px) translate(0px, 0px);
  }
  75% {
    -webkit-transform: translate(0.5000076293945312px, 127px)
      translate(-0.5000076293945312px, -127px) translate(-194px, 0px);
    transform: translate(0.5000076293945312px, 127px)
      translate(-0.5000076293945312px, -127px) translate(-194px, 0px);
  }
  100% {
    -webkit-transform: translate(0.5000076293945312px, 127px)
      translate(-0.5000076293945312px, -127px) translate(-194px, 0px);
    transform: translate(0.5000076293945312px, 127px)
      translate(-0.5000076293945312px, -127px) translate(-194px, 0px);
  }
}
@keyframes kf_el_YJBkAijzRs_an_55W5iDRlP {
  0% {
    -webkit-transform: translate(0.5000076293945312px, 127px)
      translate(-0.5000076293945312px, -127px) translate(0px, 0px);
    transform: translate(0.5000076293945312px, 127px)
      translate(-0.5000076293945312px, -127px) translate(0px, 0px);
  }
  75% {
    -webkit-transform: translate(0.5000076293945312px, 127px)
      translate(-0.5000076293945312px, -127px) translate(-194px, 0px);
    transform: translate(0.5000076293945312px, 127px)
      translate(-0.5000076293945312px, -127px) translate(-194px, 0px);
  }
  100% {
    -webkit-transform: translate(0.5000076293945312px, 127px)
      translate(-0.5000076293945312px, -127px) translate(-194px, 0px);
    transform: translate(0.5000076293945312px, 127px)
      translate(-0.5000076293945312px, -127px) translate(-194px, 0px);
  }
}
@-webkit-keyframes kf_el_ipQHtzRGwH_an_VaSOypvfU {
  0% {
    -webkit-transform: translate(0.5000076293945312px, 127px)
      translate(-0.5000076293945312px, -127px) translate(192px, 0px);
    transform: translate(0.5000076293945312px, 127px)
      translate(-0.5000076293945312px, -127px) translate(192px, 0px);
  }
  75% {
    -webkit-transform: translate(0.5000076293945312px, 127px)
      translate(-0.5000076293945312px, -127px) translate(0px, 0px);
    transform: translate(0.5000076293945312px, 127px)
      translate(-0.5000076293945312px, -127px) translate(0px, 0px);
  }
  100% {
    -webkit-transform: translate(0.5000076293945312px, 127px)
      translate(-0.5000076293945312px, -127px) translate(0px, 0px);
    transform: translate(0.5000076293945312px, 127px)
      translate(-0.5000076293945312px, -127px) translate(0px, 0px);
  }
}
@keyframes kf_el_ipQHtzRGwH_an_VaSOypvfU {
  0% {
    -webkit-transform: translate(0.5000076293945312px, 127px)
      translate(-0.5000076293945312px, -127px) translate(192px, 0px);
    transform: translate(0.5000076293945312px, 127px)
      translate(-0.5000076293945312px, -127px) translate(192px, 0px);
  }
  75% {
    -webkit-transform: translate(0.5000076293945312px, 127px)
      translate(-0.5000076293945312px, -127px) translate(0px, 0px);
    transform: translate(0.5000076293945312px, 127px)
      translate(-0.5000076293945312px, -127px) translate(0px, 0px);
  }
  100% {
    -webkit-transform: translate(0.5000076293945312px, 127px)
      translate(-0.5000076293945312px, -127px) translate(0px, 0px);
    transform: translate(0.5000076293945312px, 127px)
      translate(-0.5000076293945312px, -127px) translate(0px, 0px);
  }
}
#el_CDvcutA-y * {
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
  -webkit-animation-timing-function: cubic-bezier(0, 0, 1, 1);
  animation-timing-function: cubic-bezier(0, 0, 1, 1);
}
#el_ipQHtzRGwH {
  fill: none;
  stroke: #4abaf7;
  stroke-width: 2;
  stroke-miterlimit: 10;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  opacity: 0;
  -webkit-animation-name: kf_el_ipQHtzRGwH_an_FBmFsu36z;
  animation-name: kf_el_ipQHtzRGwH_an_FBmFsu36z;
  -webkit-animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
}
#el_YJBkAijzRs {
  fill: none;
  stroke: #4abaf7;
  stroke-width: 2;
  stroke-miterlimit: 10;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  opacity: 0;
  -webkit-animation-name: kf_el_YJBkAijzRs_an_D4z-ww0hq;
  animation-name: kf_el_YJBkAijzRs_an_D4z-ww0hq;
  -webkit-animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
}
#el_nx_DAFjNLl {
  fill: none;
  stroke: #eaeaea;
  stroke-width: 2;
  stroke-linecap: round;
  stroke-miterlimit: 10;
  stroke-dasharray: 1, 8;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  opacity: 0;
  -webkit-animation-name: kf_el_nx_DAFjNLl_an_mIyMkvVwu;
  animation-name: kf_el_nx_DAFjNLl_an_mIyMkvVwu;
  -webkit-animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
}
#el_kEw-LN6Hmx {
  fill: none;
  stroke: #eaeaea;
  stroke-width: 2;
  stroke-linecap: round;
  stroke-miterlimit: 10;
  stroke-dasharray: 1, 8;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  opacity: 0;
  -webkit-animation-name: kf_el_kEw-LN6Hmx_an_XnGPGDHUC;
  animation-name: kf_el_kEw-LN6Hmx_an_XnGPGDHUC;
  -webkit-animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
}
#el_Lwq4XIM_hf {
  fill: #eaeaea;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  opacity: 0;
  -webkit-animation-name: kf_el_Lwq4XIM_hf_an_gP4t4M2PI;
  animation-name: kf_el_Lwq4XIM_hf_an_gP4t4M2PI;
  -webkit-animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
}
#el_IoAzBrlRaH {
  fill: #4abaf7;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  opacity: 0;
  -webkit-animation-name: kf_el_IoAzBrlRaH_an_Bq0fVmd7S;
  animation-name: kf_el_IoAzBrlRaH_an_Bq0fVmd7S;
  -webkit-animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
}
#el_ipQHtzRGwH_an_VaSOypvfU {
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-transform: translate(0.5000076293945312px, 127px)
    translate(-0.5000076293945312px, -127px) translate(192px, 0px);
  transform: translate(0.5000076293945312px, 127px)
    translate(-0.5000076293945312px, -127px) translate(192px, 0px);
  -webkit-animation-name: kf_el_ipQHtzRGwH_an_VaSOypvfU;
  animation-name: kf_el_ipQHtzRGwH_an_VaSOypvfU;
  -webkit-animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
}
#el_YJBkAijzRs_an_55W5iDRlP {
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-transform: translate(0.5000076293945312px, 127px)
    translate(-0.5000076293945312px, -127px) translate(0px, 0px);
  transform: translate(0.5000076293945312px, 127px)
    translate(-0.5000076293945312px, -127px) translate(0px, 0px);
  -webkit-animation-name: kf_el_YJBkAijzRs_an_55W5iDRlP;
  animation-name: kf_el_YJBkAijzRs_an_55W5iDRlP;
  -webkit-animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
}
