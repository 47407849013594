.UiXohNLG_0 {
  stroke-dasharray: 74 76;
  stroke-dashoffset: 75;
  animation: UiXohNLG_draw 1500ms ease-in-out 0ms forwards;
}
.UiXohNLG_1 {
  stroke-dasharray: 24 26;
  stroke-dashoffset: 25;
  animation: UiXohNLG_draw 1500ms ease-in-out 0ms forwards;
}
.UiXohNLG_2 {
  stroke-dasharray: 24 26;
  stroke-dashoffset: 25;
  animation: UiXohNLG_draw 1500ms ease-in-out 0ms forwards;
}
.UiXohNLG_3 {
  stroke-dasharray: 24 26;
  stroke-dashoffset: 25;
  animation: UiXohNLG_draw 1500ms ease-in-out 0ms forwards;
}
.UiXohNLG_4 {
  stroke-dasharray: 24 26;
  stroke-dashoffset: 25;
  animation: UiXohNLG_draw 1500ms ease-in-out 0ms forwards;
}
.UiXohNLG_5 {
  stroke-dasharray: 24 26;
  stroke-dashoffset: 25;
  animation: UiXohNLG_draw 1500ms ease-in-out 0ms forwards;
}
.UiXohNLG_6 {
  stroke-dasharray: 24 26;
  stroke-dashoffset: 25;
  animation: UiXohNLG_draw 1500ms ease-in-out 0ms forwards;
}
.UiXohNLG_7 {
  stroke-dasharray: 24 26;
  stroke-dashoffset: 25;
  animation: UiXohNLG_draw 1500ms ease-in-out 0ms forwards;
}
.UiXohNLG_8 {
  stroke-dasharray: 24 26;
  stroke-dashoffset: 25;
  animation: UiXohNLG_draw 1500ms ease-in-out 0ms forwards;
}
.UiXohNLG_9 {
  stroke-dasharray: 24 26;
  stroke-dashoffset: 25;
  animation: UiXohNLG_draw 1500ms ease-in-out 0ms forwards;
}
.UiXohNLG_10 {
  stroke-dasharray: 24 26;
  stroke-dashoffset: 25;
  animation: UiXohNLG_draw 1500ms ease-in-out 0ms forwards;
}
.UiXohNLG_11 {
  stroke-dasharray: 24 26;
  stroke-dashoffset: 25;
  animation: UiXohNLG_draw 1500ms ease-in-out 0ms forwards;
}
.UiXohNLG_12 {
  stroke-dasharray: 24 26;
  stroke-dashoffset: 25;
  animation: UiXohNLG_draw 1500ms ease-in-out 0ms forwards;
}
.UiXohNLG_13 {
  stroke-dasharray: 24 26;
  stroke-dashoffset: 25;
  animation: UiXohNLG_draw 1500ms ease-in-out 0ms forwards;
}
.UiXohNLG_14 {
  stroke-dasharray: 24 26;
  stroke-dashoffset: 25;
  animation: UiXohNLG_draw 1500ms ease-in-out 0ms forwards;
}
.UiXohNLG_15 {
  stroke-dasharray: 24 26;
  stroke-dashoffset: 25;
  animation: UiXohNLG_draw 1500ms ease-in-out 0ms forwards;
}
.UiXohNLG_16 {
  stroke-dasharray: 24 26;
  stroke-dashoffset: 25;
  animation: UiXohNLG_draw 1500ms ease-in-out 0ms forwards;
}
.UiXohNLG_17 {
  stroke-dasharray: 400 402;
  stroke-dashoffset: 401;
  animation: UiXohNLG_draw 1500ms ease-in-out 0ms forwards;
}
@keyframes UiXohNLG_draw {
  100% {
    stroke-dashoffset: 0;
  }
}
@keyframes UiXohNLG_fade {
  0% {
    stroke-opacity: 1;
  }
  92.98245614035088% {
    stroke-opacity: 1;
  }
  100% {
    stroke-opacity: 0;
  }
}
