.TVyeBBEj_0 {
  stroke-dasharray: 296 298;
  stroke-dashoffset: 297;
  animation: TVyeBBEj_draw 1333ms ease-in-out 0ms forwards;
}
.TVyeBBEj_1 {
  stroke-dasharray: 104 106;
  stroke-dashoffset: 105;
  animation: TVyeBBEj_draw 1333ms ease-in-out 166ms forwards;
}
.TVyeBBEj_2 {
  stroke-dasharray: 104 106;
  stroke-dashoffset: 105;
  animation: TVyeBBEj_draw 1333ms ease-in-out 333ms forwards;
}
.TVyeBBEj_3 {
  stroke-dasharray: 132 134;
  stroke-dashoffset: 133;
  animation: TVyeBBEj_draw 1333ms ease-in-out 500ms forwards;
}
.TVyeBBEj_4 {
  stroke-dasharray: 132 134;
  stroke-dashoffset: 133;
  animation: TVyeBBEj_draw 1333ms ease-in-out 666ms forwards;
}
@keyframes TVyeBBEj_draw {
  100% {
    stroke-dashoffset: 0;
  }
}
@keyframes TVyeBBEj_fade {
  0% {
    stroke-opacity: 1;
  }
  93.54838709677419% {
    stroke-opacity: 1;
  }
  100% {
    stroke-opacity: 0;
  }
}
