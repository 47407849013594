.HfcobzHh_0 {
  stroke-dasharray: 287 289;
  stroke-dashoffset: 288;
  animation: HfcobzHh_draw 1500ms ease-in-out 0ms forwards;
}
.HfcobzHh_1 {
  stroke-dasharray: 97 99;
  stroke-dashoffset: 98;
  animation: HfcobzHh_draw 1500ms ease-in-out 0ms forwards;
}
@keyframes HfcobzHh_draw {
  100% {
    stroke-dashoffset: 0;
  }
}
@keyframes HfcobzHh_fade {
  0% {
    stroke-opacity: 1;
  }
  92.98245614035088% {
    stroke-opacity: 1;
  }
  100% {
    stroke-opacity: 0;
  }
}
