.CqAhZOYQ_0 {
  stroke-dasharray: 368 370;
  stroke-dashoffset: 369;
  animation: CqAhZOYQ_draw 1333ms ease-in-out 0ms forwards;
}
.CqAhZOYQ_1 {
  stroke-dasharray: 29 31;
  stroke-dashoffset: 30;
  animation: CqAhZOYQ_draw 1333ms ease-in-out 222ms forwards;
}
.CqAhZOYQ_2 {
  stroke-dasharray: 20 22;
  stroke-dashoffset: 21;
  animation: CqAhZOYQ_draw 1333ms ease-in-out 444ms forwards;
}
.CqAhZOYQ_3 {
  stroke-dasharray: 64 66;
  stroke-dashoffset: 65;
  animation: CqAhZOYQ_draw 1333ms ease-in-out 666ms forwards;
}
@keyframes CqAhZOYQ_draw {
  100% {
    stroke-dashoffset: 0;
  }
}
@keyframes CqAhZOYQ_fade {
  0% {
    stroke-opacity: 1;
  }
  93.54838709677419% {
    stroke-opacity: 1;
  }
  100% {
    stroke-opacity: 0;
  }
}
