@-webkit-keyframes kf_el_otztlCRqoN_an_Iom32KocD {
  0% {
    -webkit-transform: translate(98.69999313354492px, 96.94386672973633px)
      scale(0.5, 0.5) translate(-98.69999313354492px, -96.94386672973633px);
    transform: translate(98.69999313354492px, 96.94386672973633px)
      scale(0.5, 0.5) translate(-98.69999313354492px, -96.94386672973633px);
  }
  75% {
    -webkit-transform: translate(98.69999313354492px, 96.94386672973633px)
      scale(1, 1) translate(-98.69999313354492px, -96.94386672973633px);
    transform: translate(98.69999313354492px, 96.94386672973633px) scale(1, 1)
      translate(-98.69999313354492px, -96.94386672973633px);
  }
  100% {
    -webkit-transform: translate(98.69999313354492px, 96.94386672973633px)
      scale(1, 1) translate(-98.69999313354492px, -96.94386672973633px);
    transform: translate(98.69999313354492px, 96.94386672973633px) scale(1, 1)
      translate(-98.69999313354492px, -96.94386672973633px);
  }
}
@keyframes kf_el_otztlCRqoN_an_Iom32KocD {
  0% {
    -webkit-transform: translate(98.69999313354492px, 96.94386672973633px)
      scale(0.5, 0.5) translate(-98.69999313354492px, -96.94386672973633px);
    transform: translate(98.69999313354492px, 96.94386672973633px)
      scale(0.5, 0.5) translate(-98.69999313354492px, -96.94386672973633px);
  }
  75% {
    -webkit-transform: translate(98.69999313354492px, 96.94386672973633px)
      scale(1, 1) translate(-98.69999313354492px, -96.94386672973633px);
    transform: translate(98.69999313354492px, 96.94386672973633px) scale(1, 1)
      translate(-98.69999313354492px, -96.94386672973633px);
  }
  100% {
    -webkit-transform: translate(98.69999313354492px, 96.94386672973633px)
      scale(1, 1) translate(-98.69999313354492px, -96.94386672973633px);
    transform: translate(98.69999313354492px, 96.94386672973633px) scale(1, 1)
      translate(-98.69999313354492px, -96.94386672973633px);
  }
}
@-webkit-keyframes kf_el_M1iolMtFYm_an_yMS-3z5h- {
  0% {
    -webkit-transform: translate(97.54999697208405px, 96.94999694824219px)
      scale(0.7, 0.7) translate(-97.54999697208405px, -96.94999694824219px);
    transform: translate(97.54999697208405px, 96.94999694824219px)
      scale(0.7, 0.7) translate(-97.54999697208405px, -96.94999694824219px);
  }
  75% {
    -webkit-transform: translate(97.54999697208405px, 96.94999694824219px)
      scale(1, 1) translate(-97.54999697208405px, -96.94999694824219px);
    transform: translate(97.54999697208405px, 96.94999694824219px) scale(1, 1)
      translate(-97.54999697208405px, -96.94999694824219px);
  }
  100% {
    -webkit-transform: translate(97.54999697208405px, 96.94999694824219px)
      scale(1, 1) translate(-97.54999697208405px, -96.94999694824219px);
    transform: translate(97.54999697208405px, 96.94999694824219px) scale(1, 1)
      translate(-97.54999697208405px, -96.94999694824219px);
  }
}
@keyframes kf_el_M1iolMtFYm_an_yMS-3z5h- {
  0% {
    -webkit-transform: translate(97.54999697208405px, 96.94999694824219px)
      scale(0.7, 0.7) translate(-97.54999697208405px, -96.94999694824219px);
    transform: translate(97.54999697208405px, 96.94999694824219px)
      scale(0.7, 0.7) translate(-97.54999697208405px, -96.94999694824219px);
  }
  75% {
    -webkit-transform: translate(97.54999697208405px, 96.94999694824219px)
      scale(1, 1) translate(-97.54999697208405px, -96.94999694824219px);
    transform: translate(97.54999697208405px, 96.94999694824219px) scale(1, 1)
      translate(-97.54999697208405px, -96.94999694824219px);
  }
  100% {
    -webkit-transform: translate(97.54999697208405px, 96.94999694824219px)
      scale(1, 1) translate(-97.54999697208405px, -96.94999694824219px);
    transform: translate(97.54999697208405px, 96.94999694824219px) scale(1, 1)
      translate(-97.54999697208405px, -96.94999694824219px);
  }
}
@-webkit-keyframes kf_el_M1iolMtFYm_an_xn7w-BybQ {
  0% {
    opacity: 0;
  }
  75% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}
@keyframes kf_el_M1iolMtFYm_an_xn7w-BybQ {
  0% {
    opacity: 0;
  }
  75% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes kf_el_otztlCRqoN_an_h5hvrf2RT {
  0% {
    opacity: 0;
  }
  75% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}
@keyframes kf_el_otztlCRqoN_an_h5hvrf2RT {
  0% {
    opacity: 0;
  }
  75% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}
#el_FTCV8kTo3 * {
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
  -webkit-animation-timing-function: cubic-bezier(0, 0, 1, 1);
  animation-timing-function: cubic-bezier(0, 0, 1, 1);
}
#el_otztlCRqoN {
  fill: none;
  stroke: #4abaf7;
  stroke-width: 2;
  stroke-miterlimit: 10;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  opacity: 0;
  -webkit-animation-name: kf_el_otztlCRqoN_an_h5hvrf2RT;
  animation-name: kf_el_otztlCRqoN_an_h5hvrf2RT;
  -webkit-animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
}
#el_ARU7slo6JD {
  fill: none;
  stroke: #4abaf7;
  stroke-width: 2;
  stroke-miterlimit: 10;
}
#el_6H4na0kv5G {
  fill: none;
  stroke: #4abaf7;
  stroke-width: 2;
  stroke-miterlimit: 10;
}
#el_ZasmGalhpW {
  fill: none;
  stroke: #4abaf7;
  stroke-width: 2;
  stroke-miterlimit: 10;
}
#el_GHZSZyQWht {
  fill: none;
  stroke: #4abaf7;
  stroke-width: 2;
  stroke-miterlimit: 10;
}
#el_BK_U73yT-U {
  fill: none;
  stroke: #4abaf7;
  stroke-width: 2;
  stroke-miterlimit: 10;
}
#el_hk_t1lXxSq {
  fill: none;
  stroke: #4abaf7;
  stroke-width: 2;
  stroke-miterlimit: 10;
}
#el_Vj3yWh-13G {
  fill: none;
  stroke: #4abaf7;
  stroke-width: 2;
  stroke-miterlimit: 10;
}
#el_4xOFe42wRk {
  fill: none;
  stroke: #4abaf7;
  stroke-width: 2;
  stroke-miterlimit: 10;
}
#el_M1iolMtFYm {
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  opacity: 0;
  -webkit-animation-name: kf_el_M1iolMtFYm_an_xn7w-BybQ;
  animation-name: kf_el_M1iolMtFYm_an_xn7w-BybQ;
  -webkit-animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
}
#el_M1iolMtFYm_an_yMS-3z5h- {
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-transform: translate(97.54999697208405px, 96.94999694824219px)
    scale(0.7, 0.7) translate(-97.54999697208405px, -96.94999694824219px);
  transform: translate(97.54999697208405px, 96.94999694824219px) scale(0.7, 0.7)
    translate(-97.54999697208405px, -96.94999694824219px);
  -webkit-animation-name: kf_el_M1iolMtFYm_an_yMS-3z5h-;
  animation-name: kf_el_M1iolMtFYm_an_yMS-3z5h-;
  -webkit-animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
}
#el_otztlCRqoN_an_Iom32KocD {
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-transform: translate(98.69999313354492px, 96.94386672973633px)
    scale(0.7, 0.7) translate(-98.69999313354492px, -96.94386672973633px);
  transform: translate(98.69999313354492px, 96.94386672973633px) scale(0.7, 0.7)
    translate(-98.69999313354492px, -96.94386672973633px);
  -webkit-animation-name: kf_el_otztlCRqoN_an_Iom32KocD;
  animation-name: kf_el_otztlCRqoN_an_Iom32KocD;
  -webkit-animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
}
