
  @-webkit-keyframes kf_el_yEzVgbEnuq_an_QT_aRxAE8 {
    0% {
      -webkit-transform: translate(95px, 31px) translate(-95px, -31px)
        translate(0px, 100px);
      transform: translate(95px, 31px) translate(-95px, -31px)
        translate(0px, 100px);
    }
    50% {
      -webkit-transform: translate(95px, 31px) translate(-95px, -31px)
        translate(0px, 0px);
      transform: translate(95px, 31px) translate(-95px, -31px) translate(0px, 0px);
    }
    100% {
      -webkit-transform: translate(95px, 31px) translate(-95px, -31px)
        translate(0px, 0px);
      transform: translate(95px, 31px) translate(-95px, -31px) translate(0px, 0px);
    }
  }
  @keyframes kf_el_yEzVgbEnuq_an_QT_aRxAE8 {
    0% {
      -webkit-transform: translate(95px, 31px) translate(-95px, -31px)
        translate(0px, 100px);
      transform: translate(95px, 31px) translate(-95px, -31px)
        translate(0px, 100px);
    }
    50% {
      -webkit-transform: translate(95px, 31px) translate(-95px, -31px)
        translate(0px, 0px);
      transform: translate(95px, 31px) translate(-95px, -31px) translate(0px, 0px);
    }
    100% {
      -webkit-transform: translate(95px, 31px) translate(-95px, -31px)
        translate(0px, 0px);
      transform: translate(95px, 31px) translate(-95px, -31px) translate(0px, 0px);
    }
  }
  #el_NaU_-9vIv * {
    -webkit-animation-duration: 3s;
    animation-duration: 3s;
    -webkit-animation-iteration-count: 1;
    animation-iteration-count: 1;
    -webkit-animation-timing-function: cubic-bezier(0, 0, 1, 1);
    animation-timing-function: cubic-bezier(0, 0, 1, 1);
    
  }
  #el_gL-DACtn_R {
    -webkit-clip-path: url(#SVGID_2_);
    clip-path: url(#SVGID_2_);
  }
  #el_yEzVgbEnuq {
    fill: none;
    stroke: #4abaf7;
    stroke-width: 2;
  }
  #el_cEBBkr4tF6 {
    fill: #eaeaea;
  }
  #el_EjFbtbwS2D {
    fill: #eaeaea;
  }
  #el_B8m5BWzwCt {
    fill: #eaeaea;
  }
  #el_yEzVgbEnuq_an_QT_aRxAE8 {
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-transform: translate(95px, 31px) translate(-95px, -31px)
      translate(0px, 100px);
    transform: translate(95px, 31px) translate(-95px, -31px) translate(0px, 100px);
    -webkit-animation-name: kf_el_yEzVgbEnuq_an_QT_aRxAE8;
    animation-name: kf_el_yEzVgbEnuq_an_QT_aRxAE8;
    -webkit-animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  }



