.LYhugBaC_0 {
  stroke-dasharray: 125 127;
  stroke-dashoffset: 126;
  animation: LYhugBaC_draw 1333ms ease-in-out 0ms forwards;
}
.LYhugBaC_1 {
  stroke-dasharray: 78 80;
  stroke-dashoffset: 79;
  animation: LYhugBaC_draw 1333ms ease-in-out 333ms forwards;
}
.LYhugBaC_2 {
  stroke-dasharray: 209 211;
  stroke-dashoffset: 210;
  animation: LYhugBaC_draw 1333ms ease-in-out 666ms forwards;
}
@keyframes LYhugBaC_draw {
  100% {
    stroke-dashoffset: 0;
  }
}
@keyframes LYhugBaC_fade {
  0% {
    stroke-opacity: 1;
  }
  93.54838709677419% {
    stroke-opacity: 1;
  }
  100% {
    stroke-opacity: 0;
  }
}
